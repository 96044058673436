import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from './index.module.css';

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query {
      bananaJuice: file(relativePath: { eq: "banana-juice.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foodTruck: file(relativePath: { eq: "food-truck.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      softwareDevelopment: file(
        relativePath: { eq: "software-development.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      consulting: file(relativePath: { eq: "consulting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Home" />
      <main>
        <section>
          <h1>株式会社nullfull</h1>
        </section>
        <hr />
        <section>
          <h2>事業内容</h2>
          <div className="bananajuice">
            <h3>バナナジュース専門店　ルンルンバナナ</h3>
            <Image fluid={query.bananaJuice.childImageSharp.fluid} />
            <div>
              <div>
                <p style={{ margin: '8px 0' }}>
                  東京都江東区にてバナナジュース専門店を運営しています。
                  都営新宿線/東京メトロ半蔵門線の住吉駅B1出口より徒歩4分です。
                  <br />
                  しっかりと追熟をしたバナナを2本分使った自慢の濃厚なバナナジュースです。
                  ミルク味、チョコ味、黒ごま味など様々なフレーバーを用意しています。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="foodtruck">
            <h3>フードトラック</h3>
            <Image fluid={query.foodTruck.childImageSharp.fluid} />
            <div>
              <p style={{ margin: '8px 0' }}>メニュー例</p>
              <ul>
                <li>パスタ、ピザなどの食事</li>
                <li>チュロス、ジェラートなどのスイーツ</li>
                <li>タピオカドリンク、バナナジュースなどドリンク</li>
                <li>その他にご要望に合わせて対応可能です</li>
              </ul>
              <p style={{ margin: '8px 0' }}>出店方式</p>
              <ul>
                <li>スーパー、ホームセンターなどの店舗や学校への定期出店</li>
                <li>地域のイベント、各種フェスへの出店</li>
                <li>ロケ地、企業イベントへのケータリング</li>
                <li>その他お問い合わせいただければ可能な限り対応いたします</li>
              </ul>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <a
                href="https://foodtruck.nullfull.co.jp/"
                className={styles.foodtruckButton}
              >
                Ninja Kitchen (ニンジャキッチン) Webサイト
              </a>
            </div>
          </div>
          <hr />
          <div style={{ margin: '24px 0' }}>
            <h3>ソフトウェア開発</h3>
            <Image fluid={query.softwareDevelopment.childImageSharp.fluid} />
            <div>
              <p style={{ margin: '8px 0' }}>領域</p>
              <ul>
                <li>Webサービス、ホームページの開発、運用保守</li>
                <li>スマートフォンアプリの開発、運用保守</li>
                <li>その他についても対応可能ですのでご相談ください</li>
              </ul>
            </div>
          </div>
          <hr />
          <div style={{ margin: '24px 0' }}>
            <h3>ITコンサルティング</h3>
            <Image fluid={query.consulting.childImageSharp.fluid} />
            <div>
              <p style={{ margin: '8px 0' }}>領域</p>
              <ul>
                <li>IT技術を用いた事業の効率化</li>
                <li>バックオフィス業務のIT化</li>
                <li>IT機器の選定、導入支援</li>
                <li>その他についても対応可能ですのでご相談ください</li>
              </ul>
            </div>
          </div>
        </section>
        <hr />
        <section>
          <h2>会社概要</h2>
          <div>
            <address>
              会社名: 株式会社nullfull (株式会社ヌルフル)
              <br />
              代表者: 橋本 晃樹
              <br />
              所在地: 〒103-0026 東京都中央区日本橋兜町17-2 兜町第六葉山ビル 4階
              <br />
              E-Mail:{' '}
              <a href="mailto:contact@nullfull.co.jp">contact@nullfull.co.jp</a>
            </address>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;
